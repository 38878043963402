@font-face {
  font-family: "Supercell Text";
  src: url("/fonts/supercelltext_w_rg.ttf");
}

@font-face {
  font-family: "Supercell Headline";
  src: url("/fonts/supercellheadline-heavy.ttf");
  font-weight: bold;
}

body,
html,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  font-family: "Supercell Text";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --background: #000000;
  --text: #FFFFFF;

  --dark: #191919;
  --medium: #666666;
  --light: #CCCCCC;
  --lighter: #F2F2F2;

  --accent-blue: #2D85F3;
  --accent-green: #21C49F;
  --accent-pink: #FB62AA;
  --accent-yellow: #F7DB1C;

  background-color: var(--background);
  color: var(--text);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Supercell Headline";
  font-weight: bold;
  margin-top: 0;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 4px;
}

:hover::-webkit-scrollbar-track {
  background-color: var(--dark);
}

:hover::-webkit-scrollbar-thumb {
  background-color: var(--medium);
}
div.ChatLog {
  grid-row: 1 / 2;
  grid-column: 2 / 4;

  padding: 16px 32px;
  border-bottom: 1px solid var(--dark);
  overflow-y: auto;
}

div.overlay {
  grid-row: 1 / 2;
  grid-column: 2 / 4;

  z-index: 1;
  pointer-events: none;
  background: linear-gradient(to bottom, black, transparent, black);
  border-bottom: 1px solid var(--dark);

  transition: opacity 200ms;

  &.disabled {
    opacity: 0;
  }
}
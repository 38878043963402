div.ActionDialog {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  place-items: center;
  place-content: center;
  gap: 8px;

  backdrop-filter: blur(8px);

  transition: opacity 200ms, transform 200ms, backdrop-filter 200ms;
  will-change: opacity, transform, backdrop-filter;

  z-index: 2;

  &.hidden {
    opacity: 0;
    transform: translateY(16px);
    backdrop-filter: blur(0px);
    pointer-events: none;
  }

  h2 {
    width: 100%;
    text-align: center;
  }

  >div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    place-items: center;
    place-content: center;
    gap: 8px;
    max-width: 900px;

    >div {
      display: flex;
      place-items: center;

      width: 400px;
      background-color: var(--background);
      border: 1px solid var(--dark);
      border-radius: 8px;
      padding: 8px;

      cursor: pointer;

      span:first-child {
        display: inline-flex;
        place-items: center;
        place-content: center;
        width: 32px;
        height: 32px;
        font-family: "Supercell Headline";
        color: var(--text);
        background-color: var(--dark);
        border: 1px solid var(--medium);
        border-radius: 2px;
      }

      span:last-child {
        flex: 1;
        text-align: center;
      }

      &:hover,
      &.highlighted {
        background-color: var(--dark);
      }
    }
  }
}
div.App {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: calc(65% - 32px) calc(35% - 32px) 64px;
  width: 100%;
  height: 100%;

  >div {
    border-right: 1px solid var(--dark);
  }

  div.Footer {
    display: flex;
    place-items: center;
    place-content: center;
    grid-column: 1 / 5;
    border: none;
    border-top: 1px solid var(--dark);
    color: rgba(255, 255, 255, 0.5);

    strong {
      display: inline-flex;
      place-items: center;
      place-content: center;
      margin: 0 8px;
      width: 24px;
      height: 24px;
      color: var(--text);
      background-color: var(--dark);
      border: 1px solid var(--medium);
      border-radius: 2px;
      cursor: pointer;
      transition: background-color 200ms;

      &:hover {
        background-color: var(--medium);
      }
    }

    img {
      position: absolute;
      bottom: 16px;
      left: 16px;
      height: 32px;
    }
  }
}